import { Link, useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import { searchPages, searchProjects } from '../../api/axios';
import arrR from '../../assets/icons/arrR.svg';
import './style.css';

function Search({ props }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [mySearch_Pages, setMySearch_Pages] = useState([]);
  const [mySearch_Projects, setMySearch_Projects] = useState([]);
  const [queryFilter, setQueryFilter] = useQueryParam('search', StringParam);

  useEffect(() => {
    setLoading(true);

    if (queryFilter && queryFilter !== '') {
      searchProjects(queryFilter, intl.locale).then((projectsRes) => {
        setMySearch_Projects(projectsRes.data);
        searchPages(queryFilter, intl.locale).then((pagesRes) => {
          setMySearch_Pages(pagesRes.data);
          setLoading(false);
        });
      });
    } else {
      navigate('/');
    }
  }, [queryFilter]);

  return (
    <div className="search_main" style={{ background: 'linear-gradient(180deg, #f2f5f7 70%, white 30%)', padding: '140px 0' }}>
      <div className="search_title">
        {intl.formatMessage({ id: 'your_search' })}
        { queryFilter || ''}
      </div>
      {(mySearch_Projects.concat(mySearch_Pages).length < 1 && !loading)
        && (
        <div>
          {intl.formatMessage({ id: 'search_no_results' })}
        </div>
        )}
      {!loading && (
      <div
        style={{
          maxWidth: 1400,
          width: '85%',
          top: '50%',
          margin: '0 auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {mySearch_Pages.concat(mySearch_Projects).map((res) => (
          <div className="search_card_container">
            <div style={{ margin: '27px 27px 0 27px ' }}>
              <div className="search_card_title">
                {res.title.rendered ? res.title.rendered : 'No Title'}
              </div>

              {res.acf.projectPreview
                               && <div className="search_card_desc" dangerouslySetInnerHTML={{ __html: res.acf.projectPreview.projectExcerpt ? res.acf.projectPreview.projectExcerpt : '' }} />}

              {res.excerpt
                               && <div className="search_card_desc" dangerouslySetInnerHTML={{ __html: res.excerpt.rendered ? res.excerpt.rendered : '' }} />}
            </div>
            <Link to={`/${res.slug}`} style={{ padding: 15, alignSelf: 'flex-end', cursor: 'pointer' }}>

              <img src={arrR} alt="cardSearch" className="search_card_arrow" />

            </Link>

          </div>
        ))}
      </div>
      )}
    </div>
  );
}

export default Search;
