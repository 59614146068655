import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Search from '../components/Search/Search';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function SearchPage() {


  return (
    <MainLayout>
      <Search />
    </MainLayout>
  );
}

export default injectIntl(SearchPage);
